<template>
  <div>
    <prevent-leave
      ref="preventLeave"
      v-model="isPrevent"
      :title="$t('common.trainLeave.popup.title')"
      :saveButton="
        $t('common.trainLeave.popup.saveButton', { currentPage: currentPage })
      "
      :leaveButton="$t('common.trainLeave.popup.leaveButton')"
      leaveButtonColor="sub"
      @save-event="closePrevent"
    >
      <texts class="prevent-leave-text" isShowAll size="small">
        <span
          v-html="
            $t('common.trainLeave.reason.first', {
              leavePage: leavePage,
              currentPage: currentPage
            })
          "
        />
        <span
          v-html="
            $t('common.trainLeave.reason.second', { currentPage: currentPage })
          "
        />
        <span v-html="$t('common.trainLeave.reason.third')" />
      </texts>
    </prevent-leave>
    <dataset-setting
      v-bind="data"
      :pageLoading="pageLoading"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      :projectItems="projectItems"
      :projectItemsLoading="projectItemsLoading"
      :datasetTableLoading="datasetTableLoading"
      :rawSamples="trainingDatasetDetail"
      :datasetUnstructuredSamples="trainingDatasetSample"
      :datasourceList="datasourceList"
      :datasourceLoading="datasourceLoading"
      :datasourceItems="datasourceItems"
      :datasourceItemsTableLoading="datasourceItemsTableLoading"
      :datasourceSetComp="datasourceSetComp"
      :datasourceTestRes="datasourceTestRes"
      :datasourceTestLoading="datasourceTestLoading"
      :datasourceSqlItems="datasourceSqlItems"
      :sqlTablePreviewData="sqlTablePreviewData"
      :loadingSqlTablePreview="loadingSqlTablePreview"
      :trainingDatasetTooLarge="trainingDatasetTooLarge"
      :headerTabs="headerTabs"
      :sidebar="sidebar"
      :incorrectOrder="incorrectOrder"
      @show-table="fetchDetailData($event)"
      @upload-file="uploadFile($event)"
      @on-file-input="fileCheck($event)"
      @test-datasource="testDatasource($event)"
      @set-datasource="setDatasource($event)"
      @import-dataset="importDataset($event)"
      @select-dataset="selectDataset($event)"
      @close-modal="closePopup($event)"
      @reave-to-project="toProject()"
      @open-datasource="openDatasource($event)"
      @open-datasource-item="openDatasourceItem($event)"
      @force-select-other-account-dataset="
        forceSelectOtherAccountDataset($event)
      "
      @input-create-form="inputCreateForm($event)"
      @reset-status="resetStatus"
      @not-prevent="notPrevent"
      @restart-prevent="restartPrevent"
      @page-back="pageBack"
      @go-manual-dataset-type="goManualDatasetType"
      @get-sql-list="getSqlList"
      @select-sql-preview-table="selectSqlPreviewTable"
      @show-too-large-data="showTooLargeData"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { checkTrainingOrder } from '@/lib/progress-training.js'
import { uploadViaSocket } from '@/lib/upload.js'
import { datasetValidator } from '@/lib/validator/dataset.js'
import { datasourceValidator } from '@/lib/validator/datasource.js'
import {
  checkOuterTraining,
  checkCorrectBackPage
} from '@/lib/training-pages-correct-behavior'
import { datasetLargeFileSize } from '@/lib/dataset.js'
import setMountedTimer from '@/mixin/set-mounted-timer'

import datasetSetting from '@/components/templates/train/dataset-setting.vue'
import preventLeave from '@/components/molecules/prevent-leave'

export default {
  components: {
    datasetSetting,
    preventLeave
  },
  mixins: [setMountedTimer],
  async beforeRouteLeave(to, from, next) {
    this.isPrevent = this.checkPrevent(to.name)
    if (this.isPrevent) {
      this.leavePageName = to.name
      const result = await this.$refs.preventLeave.$confirm()
      if (result) {
        this.deleteProgressTraining({ projectId: this.projectId })
        this.setProgressTraining({
          item: this.projectId,
          setType: 'project'
        })

        this.$router.push({
          name: 'projectDetail',
          params: {
            projectId: this.projectId
          }
        })
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.$waitConnected()
      vm.pageLoading = true
      vm.resetDataset()
      await vm.fetchAccountInfo()
      await vm.loadProjectDatasets()
      await vm.loadProjectDetail(vm.projectId)

      vm.setAccountInfo()
      vm.setUsageRestriction({ projectId: vm.projectId })

      await vm.setModelSettingInfo(parseInt(to.params.projectId))

      // 学習の再設定時、情報が消えているので再設定する
      if (!vm.progressTraining) {
        await vm.setProgressTraining({
          item: parseInt(to.params.projectId),
          setType: 'project'
        })
        await vm.setModelSettingInfo(parseInt(to.params.projectId))
      }

      await vm.fetchDataSources()

      vm.pageLoading = false

      vm.incorrectOrder = !checkTrainingOrder({
        projectId: vm.projectId,
        stage: 'dataset',
        progressList: vm.modelSettingList
      })

      if (vm.incorrectOrder) {
        vm.showPopup('preventTrainingStatus')
      }
    })
  },
  computed: {
    ...mapGetters('auth', ['accountInfo']),
    ...mapGetters('datasets', ['datasetLoading']),
    // 今はページ読み込み時にデータソースも取得していますが、実装時にタブをデータソースからインポートに切り替えたときに取得するようにしてもいいかも？
    ...mapGetters('datasource', ['datasourceList', 'datasourceLoading']),
    ...mapGetters('project', ['projectList', 'projectLoading']),
    ...mapGetters('settings', ['rowsPerPage']),
    ...mapGetters('models', ['modelSettingList']),
    ...mapGetters('trainingDataset', [
      'trainingDatasetDetail',
      'loadingDatasetDetail',
      'fetchDetailError',
      'trainingDatasetId',
      'trainingDatasetAccountId',
      'trainingDatasetSample',
      'trainingDatasetInPreprocess',
      'trainingDatasetTooLarge'
    ]),
    projectId() {
      return parseInt(this.$route.params.projectId)
    },
    projectInfo() {
      if (this.projectId) {
        return this.projectList[this.projectId]
      }
      return null
    },
    headerTabs() {
      return {
        // ヘッダーのタブ
        tabs: [],
        tabSelect: 1
      }
    },
    leavePage() {
      return this.$t('common.trainLeave.titles.' + this.leavePageName)
    },
    currentPage() {
      return this.$t('common.trainLeave.titles.' + this.currentPageName)
    },
    datasetTableLoading() {
      return this.loadingDatasetDetail
    },
    datasetList() {
      const result = {}
      this.projectList[this.projectId]?.listData?.forEach((item) => {
        result[item.id] = item
      })
      return result
    }
  },
  data() {
    return {
      pageLoading: false,
      progressTraining: null,
      projectItems: null,
      projectItemsLoading: false,
      datasourceItems: [],
      datasourceSqlItems: [],
      sqlTablePreviewData: {},
      loadingSqlTablePreview: false,
      datasourceItemsTableLoading: false, // データソースのテーブルの読み込み中
      datasourceSetComp: false, // データソースの新規追加の完了フラグ
      datasourceTestRes: {}, // データソースのテストのレスポンス
      datasourceTestLoading: false,
      reaveToProject: false, // プロジェクト作成ページに遷移するときにポップアップをだすフラグ
      inPreprocessing: false, // 前処理中かどうか
      incorrectOrder: false,
      isPrevent: false,
      correctTransition: false,
      currentPageName: this.$route.name,
      leavePageName: null,
      data: {
        body: {
          mainData: {
            accountInfo: {},
            usageRestriction: {
              basic: true,
              education_1: true,
              free: true
            }
          }
        },
        datasourceConfig: {
          importing: false, // データソースからデータセットをインポートする時のローディングの状態
          importingError: null,
          targetTable: '', // データソースのテーブルの名前を取ってくる
          datasourceId: null // データソースのテーブルのid
        },
        upload: {
          createInfo: {
            datasetFormValidate: {
              duplicate: undefined
            },
            datasourceFormValidate: {
              duplicate: undefined
            }
          },
          progress: {
            progressStatus: '', // データセットの登録状況の設定
            progressSettingDataset: 0, // データセットの登録の進捗状況
            progressSettingDatasetMax: 0, // これがmaxまでいけば
            isLargeCsv: false
          },
          uploadLoading: false, // データセットアップロード中
          uploadLearningDataErrors: {
            errors: [],
            flag: false
          },
          uploadLearningDataWarnings: {
            fileType: null,
            reasonList: []
          },
          resetInputValue: false
        },
        errorDataset: {
          message: [], // データセットのレシピ管理画面に行く時にエラーが出たら表示されるメッセージ
          flag: false,
          inPreprocessingOtherDataset: false, // 選択されたデータセットが他人のデータ、かつ前処理中か判断する。
          cautionFlag: false
        },
        popup: {
          // ポップアップの情報
          otherAccountDataset: null,
          sizeInfo: null,
          showPopup: [] // 現在表示しているポップアップ
        },
        settingNextPage: {
          dataDetail: false,
          loadingNextPage: false,
          fileTooLarge: false
        },
        datasetExists: false
      },
      sidebar: {
        // サイドバーに表示する情報
        activeLink: 'datasetSetting',
        status: 'select'
      }
    }
  },
  methods: {
    ...mapActions('auth', ['fetchAccountInfo']),
    ...mapActions('datasource', [
      'fetchDataSources',
      'importDatasource',
      'fetchSqlList',
      'fetchSqlTablePreviewData',
      'uploadCredential',
      'testBigQueryDataSourceConnection',
      'testDataSourceConnection',
      'editDataSource',
      'editBigQueryDataSource'
    ]),
    ...mapActions('project', ['loadProjectDetail']),
    ...mapActions('models', ['deleteProgressTraining', 'getProgressTraining', 'setProgressTraining']),
    ...mapActions('trainingDataset', [
      'fetchDataset',
      'fetchDatasetInPreprocessing',
      'fetchDatasetDetail',
      'fetchDatasetDetailUnstructured',
      'fetchDatasetTable',
      'setLoadingDatasetDetail',
      'setDatasetIdAccountId',
      'setDatasetColumns',
      'setDatasetTooLargeData',
      'resetDataset'
    ]),
    ...mapMutations('datasource', ['SET_SUBMITTING']),

    async loadProjectDatasets() {
      if (this.projectId) {
        try {
          const response = await this.$sendMessageAndReceive({
            action: 'getDataList',
            projectId: this.projectId
          })
          if (response.status !== 'error') {
            const obj = {}
            response.list.forEach((item) => {
              const fullId = item.id + '-' + item.accountId
              item.fullId = fullId
              obj[item.id] = item
            })
            this.projectItems = obj
          } else {
            throw response
          }
        } catch (e) {
          console.log(e)
        } finally {
          this.data.datasetExists = Object.keys(this.projectItems).length > 0
          this.projectItemsLoading = false
        }
      }
    },
    async fetchDetailData(data) {
      this.data.errorDataset.message = []
      this.data.errorDataset.flag = false
      this.data.errorDataset.inPreprocessingOtherDataset = false
      this.resetDataset()
      this.setLoadingDatasetDetail(true)
      this.setDatasetTooLargeData(false)
      this.inPreprocessing = false
      this.sidebar.status = 'confirm'
      if (!data) return false
      try {
        // 前処理中かどうかを判定するAPI
        const req = {
          dataId: data.id,
          accountId: data.accountId
        }
        await this.fetchDatasetInPreprocessing(req)
        if (!this.data.errorDataset.inPreprocessingOtherDataset) {
          if (this.trainingDatasetInPreprocess) {
            this.data.errorDataset.cautionFlag = true
            this.data.errorDataset.message.push(
              this.$t('datasetSetting.cautions.isPreprocessingError')
            )
            this.inPreprocessing = true
          }
          if (data.accountId !== this.accountInfo.accountId) {
            this.data.errorDataset.cautionFlag = true
            if (this.inPreprocessing) {
              this.data.errorDataset.message = []
              this.data.errorDataset.inPreprocessingOtherDataset = true
              this.data.errorDataset.message.push(
                this.$t('datasetSetting.cautions.inPreprocessingOtherDataset')
              )
            } else {
              this.data.errorDataset.message.push(
                this.$t('datasetSetting.cautions.otherAccountDataset')
              )
            }
          }
        }
      } catch (ex) {
        this.log_info(ex)
        this.data.errorDataset.flag = true
        this.setLoadingDatasetDetail(false)
      }

      if (data.type === 'unstructured') {
        const unstructuredReq = {
          dataId: data.id,
          accountId: data.accountId,
          offset: 0,
          limit: 4
        }
        await this.fetchDatasetDetailUnstructured(unstructuredReq)

        const datasetInfo = {
          dataId: data.id,
          accountId: data.accountId,
          includeGraph: false,
          includeData: false
        }
        await this.fetchDatasetDetail(datasetInfo)
      } else if (data.type === 'structured') {
        if (data.size <= datasetLargeFileSize) {
          const structuredReq = {
            dataId: data.id,
            accountId: data.accountId,
            limit: this.rowsPerPage
          }
          await this.fetchDatasetDetail(structuredReq)
        } else {
          this.setDatasetTooLargeData(true)
        }
      }
      this.setDatasetIdAccountId({
        id: data.id,
        accountId: data.accountId
      })
      this.setDatasetColumns(data.columns)
      this.setLoadingDatasetDetail(false)
    },
    async showTooLargeData(data) {
      this.setLoadingDatasetDetail(true)
      const req = {
        dataId: data.id,
        accountId: data.accountId,
        limit: this.rowsPerPage
      }
      await this.fetchDatasetDetail(req)
      this.setDatasetTooLargeData(false)
      this.setLoadingDatasetDetail(false)
    },
    /**
     *  データソースの接続テスト
     */
    async testDatasource(e) {
      window.clearTimeout(this.timeoutID)
      this.datasourceTestRes = Object.assign({})
      this.datasourceTestLoading = true
      let check
      const value = e
      if (e.dbtype === 'bigquery') {
        const data = e.credential
        check = await this.testBigQueryDataSourceConnection({ value, data })
      } else {
        check = await this.testDataSourceConnection(value)
      }

      if (check !== 'error') {
        this.datasourceTestRes = Object.assign({}, this.datasourceTestRes, {
          text: this.$t('datasetList.popup.setDatasource.testSuccess'),
          status: 'success'
        })
      } else {
        this.datasourceTestRes = Object.assign({}, this.datasourceTestRes, {
          text: this.$t('datasetList.popup.setDatasource.testError'),
          status: 'error'
        })
      }
      this.datasourceTestLoading = false

      this.timeoutID = window.setTimeout(
        function () {
          this.datasourceTestRes = Object.assign({})
        }.bind(this),
        5000
      )
    },

    /** データソースの登録 */
    async setDatasource(e) {
      this.datasourceSetComp = false

      const req = e
      if (e.dbtype === 'bigquery') {
        e.action = 'addBigQueryDataSource'
        const data = e.credential
        await this.editBigQueryDataSource({ req, data })
      } else {
        e.action = 'addDataSource'
        await this.editDataSource(req)
      }
      this.datasourceSetComp = true
    },

    /** データソースのリストを開いた時の処理 */
    async openDatasource(e) {
      if (this.datasourceItems.some((x) => x.id === e.id)) {
        return false
      } else {
        this.datasourceItemsTableLoading = true
        let items = []
        let error = false
        try {
          const res = await this.$sendMessageAndReceive({
            action: 'listDataSourceItems',
            id: e.id
          })
          if (res.status === 'error') {
            throw res
          }
          items = res.result
        } catch (ex) {
          this.log_info(ex)
          error = true
        }
        this.datasourceItems.push({
          items: items,
          id: e.id,
          error
        })
        this.datasourceItemsTableLoading = false
      }
    },
    async getSqlList(id) {
      const check = this.datasourceSqlItems.some((item) => {
        return item.id === id
      })
      if (check) return

      this.datasourceItemsTableLoading = true

      const res = await this.fetchSqlList(id)

      this.datasourceSqlItems.push({
        items: res,
        id: id
      })
      this.datasourceItemsTableLoading = false
    },
    async selectSqlPreviewTable(payload) {
      this.loadingSqlTablePreview = true

      this.sqlTablePreviewData = await this.fetchSqlTablePreviewData(payload)

      this.loadingSqlTablePreview = false
    },
    setModelSettingInfo: async function (projectId) {
      if (projectId) {
        this.progressTraining = await this.getProgressTraining({
          projectId
        })
      } else {
        this.progressTraining = null
      }
    },
    /** データソースのテーブルのプレビューを取得する処理 */
    async openDatasourceItem(e) {
      this.datasourceItemsTableLoading = true
      this.sidebar.status = 'import'
      const target = this.datasourceItems.find((x) => x.id === e.id).items
      const setTableTarget = target.find((x) => x.name === e.table)
      if (setTableTarget?.table) {
        this.datasourceItemsTableLoading = false
        return false
      }
      try {
        const res = await this.$sendMessageAndReceive({
          action: 'previewDataSourceItem',
          id: e.id,
          table: e.table
        })
        this.$set(setTableTarget, 'table', res.result)
        this.data.datasourceConfig.datasourceId = e.id
        this.data.datasourceConfig.targetTable = setTableTarget
      } catch (ex) {
        this.log_info(ex)
      }
      this.datasourceItemsTableLoading = false
    },

    showPopup(e) {
      // ポップアップを表示
      if (this.data.popup.showPopup.length > 0) {
        this.data.popup.showPopup = []
        this.data.popup.showPopup.push(e)
      } else {
        this.data.popup.showPopup.push(e)
      }
    },
    closePopup(e) {
      // ポップアップを閉じる
      this.data.popup.showPopup = this.data.popup.showPopup.filter(
        (n) => n !== e
      )
    },
    toProject() {
      this.reaveToProject = true
      this.correctTransition = true
      this.$router.push({ name: 'selectMode' })
    },
    /**
     *  使用するデータセットを選択して「このデータセットを使用する」ボタンを押したときの処理
     *  本来はローカルストレージにデータセットのIDを登録する
     *  data データセットの情報
     */
    async selectDataset(data) {
      if (data.accountId !== this.accountInfo.accountId) {
        this.showPopup('otherAccountDataset')
        this.data.popup.otherAccountDataset = data
        return
      }

      this.data.settingNextPage.loadingNextPage = true
      this.data.settingNextPage.fileTooLarge = data.size > datasetLargeFileSize
      this.correctTransition = true
      this.setProgressTraining({
        item: data.id,
        setType: 'dataset',
        projectId: this.projectId,
        datasetType: data.type,
        datasetAccountId: data.accountId
      })

      await this.getAllDatasetSetting(data)

      if (this.inPreprocessing) {
        this.setProgressTraining({
          item: true,
          setType: 'laterSelectTargetColumn',
          projectId: this.projectId
        })

        this.$router.push({
          name: 'trainPreprocessing',
          params: {
            projectId: this.projectId,
            type: '0',
            id: data.id
          }
        })
        return
      }
      if (data.type === 'structured') {
        this.$router.push({
          name: 'selectTargetColumn',
          params: { datasetId: data.id }
        })
      } else if (data.type === 'unstructured') {
        this.$router.push({ name: 'trainRecipeList' })
      }
    },

    // 所有者が異なるデータセットを選択
    async forceSelectOtherAccountDataset(data) {
      this.correctTransition = true

      this.setProgressTraining({
        item: data.id,
        setType: 'dataset',
        projectId: this.projectId,
        datasetType: data.type,
        datasetAccountId: data.accountId
      })

      await this.getAllDatasetSetting(data)

      if (data.type === 'structured') {
        this.$router.push({
          name: 'selectTargetColumn',
          params: { datasetId: data.id }
        })
      } else if (data.type === 'unstructured') {
        this.$router.push({ name: 'trainRecipeList' })
      }
    },
    /**
     *  実際にはプロジェクトのデータセット管理に登録される
     *  待っている間はローディングを表示して
     *  完了後完了の表示を出して予測する列の選択画面に飛ぶ
     *  アップロード完了後にデータセットのidを取得してローカルストレージに入れる
     *  data.name 名前 data.description 説明 data.file 投入したファイル
     */
    async uploadFile(data) {
      this.SET_SUBMITTING(true)
      this.data.upload.uploadLoading = true

      this.sidebar.status = 'upload'

      const fileSize = data.file.size
      const fileName = data.file.name

      this.data.upload.progress.progressSettingDataset = 0
      this.data.upload.progress.progressSettingDatasetMax = fileSize
      this.data.upload.progress.progressStatus = 'startUploading'
      this.data.upload.uploadLearningDataErrors.errors = []
      this.data.upload.uploadLearningDataWarnings.fileType = null
      this.data.upload.uploadLearningDataWarnings.reasonList = []
      // this.data.body.addDataset.uploadLearningDataErrors.message = ''

      const request = {
        action: 'startUploading',
        name: data.name,
        description: data.description,
        fileSize: fileSize,
        fileName: fileName,
        projectId: this.projectId
      }

      let uploadDataset = null
      let uploadDatasetZip = null

      try {
        const startRes = await this.$sendMessageAndReceive(request)
        const messageId = startRes.m_id

        const { status, message } = startRes
        if (status === 'error') throw new Error(message)

        const uploadTask = uploadViaSocket(this.$socket, data.file, {
          chunkSize: 1e5,
          header: {
            action: 'uploadingData',
            m_id: messageId
          }
        })

        await Promise.all([
          uploadTask,
          this.$watchProgress(messageId, {
            getTotalProgress: (res) => {
              this.data.upload.progress.progressStatus = 'preparingData'
              this.data.upload.progress.progressSettingDataset = 0
              this.data.upload.progress.progressSettingDatasetMax = res.total
            },
            startPreparingZipData: (res) => {
              this.data.upload.progress.progressStatus = 'preparingData'
              this.data.upload.progress.progressSettingDataset = 0
              this.data.upload.progress.progressSettingDatasetMax = 1
            },
            uploadingLearningData: (res) => {
              this.data.upload.progress.progressStatus = 'uploadingLearningData'
              this.data.upload.progress.progressSettingDataset = res.loadedSize
              this.data.upload.progress.progressSettingDatasetMax =
                res.totalSize
            },
            uploaded: (res) => {
              uploadDataset = res
            },
            startPreparingData: (res) => {
              this.data.upload.progress.progressStatus = 'preparingData'

              this.data.upload.progress.progressSettingDataset = 0
              this.data.upload.progress.progressSettingDatasetMax = 1

              this.data.upload.progress.isLargeCsv = res?.isLarge ?? false
            },
            preparingData: (res) => {
              this.data.upload.progress.progressSettingDataset = res.progress
            },
            dataWarning: (res) => {
              this.data.upload.uploadLearningDataWarnings.fileType =
                res.fileType
              this.data.upload.uploadLearningDataWarnings.reasonList =
                res.warnings
            },
            finishPreparingData: (res) => {
              uploadDatasetZip = res
            }
          })
        ])
      } catch (ex) {
        this.log_info(ex)

        this.data.upload.progress.progressStatus = 'failed'
        this.data.upload.uploadLearningDataErrors.flag = true
        this.data.upload.uploadLearningDataErrors.errors.push({
          message: ex.message,
          file: ex?.error_info?.file
        })
        this.data.upload.uploadLoading = false

        this.sidebar.status = 'select'
      } finally {
        this.data.upload.progress.progressSettingDataset =
          this.data.upload.progress.progressSettingDatasetMax
        this.SET_SUBMITTING(false)

        await this.loadProjectDetail(this.projectId)

        const datasetId = uploadDataset?.dataId ?? uploadDatasetZip?.dataId
        const targetDataset = this.datasetList[datasetId]

        this.data.upload.progress.isLargeCsv = false
        if (targetDataset) {
          this.correctTransition = true

          this.setProgressTraining({
            item: datasetId,
            setType: 'dataset',
            projectId: this.projectId,
            datasetType: targetDataset.type,
            datasetAccountId: targetDataset.accountId
          })

          await this.getAllDatasetSetting(targetDataset)

          if (targetDataset.type === 'structured') {
            this.$router.push({
              name: 'selectTargetColumn',
              params: { datasetId: datasetId }
            })
          } else if (targetDataset.type === 'unstructured') {
            this.$router.push({ name: 'trainRecipeList' })
          }
        }
      }
    },

    /**
     *  実際にはプロジェクトのデータセット管理に登録される
     *  待っている間はローディングを表示して
     *  完了後完了の表示を出して予測する列の選択画面に飛ぶ
     *  インポート完了後にデータセットのidを取得してローカルストレージに入れる
     *  data.name 名前 data.description 説明 data.checked 選択した列 data.column 列の制限
     */
    async importDataset(data) {
      this.sidebar.status = 'importing'
      this.data.datasourceConfig.importing = true
      this.data.datasourceConfig.importingError = null

      try {
        const req = {
          action: 'importFromDataSource',
          id: data.id,
          table: data.table ?? null,
          name: data.name,
          description: data.description,
          import_query: data.import_query,
          projectId: this.projectId
        }
        req.columns = data.checked

        // 一つ目のifが行数を制限している時に通過するif文
        if (data.columns !== null) {
          req.limit = parseInt(data.columns)
          // 二つ目のifがmaxRows以上の大きさの行数を入力された時に正常な行数をreqに渡すif文
          if (data.columns > this.data.datasourceConfig.targetTable.n_rows) {
            req.limit = parseInt(this.data.datasourceConfig.targetTable.n_rows)
          }
        }

        const res = await this.importDatasource(req)

        if (res.status === 'error') {
          throw res
        }

        const dataId = res.result.params.data_id
        const dataAccountId = res.result.params.account_id
        this.correctTransition = true

        this.setProgressTraining({
          item: dataId,
          setType: 'dataset',
          projectId: this.projectId,
          datasetType: 'structured', // DBからインポートするのはstructuredのみ
          datasetAccountId: dataAccountId
        })

        await this.loadProjectDetail(this.projectId)
        const targetDataset = this.datasetList[dataId]

        await this.getAllDatasetSetting(targetDataset)

        this.$router.push({
          name: 'selectTargetColumn',
          params: { datasetId: dataId, projectId: this.projectId }
        })

        this.data.datasourceConfig.importing = false
      } catch (ex) {
        this.log_info(ex)
        this.data.datasourceConfig.importing = false
        if (data.import_query) {
          this.data.datasourceConfig.importingError = 'sql'
        } else {
          this.data.datasourceConfig.importingError = 'default'
        }
        this.sidebar.status = 'import'
      }
    },

    // 次のページへの遷移前の処理
    async getAllDatasetSetting(data) {
      if (
        data.id === this.trainingDatasetId &&
        data.accountId === this.trainingDatasetAccountId &&
        this.trainingDatasetDetail?.detail != null
      )
        return
      if (data.type === 'unstructured') {
        const datasetDetail = {
          dataId: data.id,
          accountId: data.accountId,
          offset: 0,
          limit: 4
        }
        await this.fetchDatasetDetailUnstructured(datasetDetail)

        const datasetInfo = {
          dataId: data.id,
          accountId: data.accountId,
          includeGraph: false,
          includeData: false
        }
        await this.fetchDatasetDetail(datasetInfo)
      } else {
        const datasetDetail = {
          dataId: data.id,
          accountId: data.accountId,
          limit: this.rowsPerPage
        }

        if (data.size > datasetLargeFileSize) {
          datasetDetail.includeGraph = false
          datasetDetail.includeData = false
        }
        await this.fetchDatasetDetail(datasetDetail)
      }
      this.setDatasetColumns(data.columns)
    },
    // データセットにデータを入れる時に走るバリデーション
    fileCheck(file) {
      this.data.upload.resetInputValue = false
      this.initUploadState()
      if (
        this.accountInfo.totalDataSize + file.size >
        this.accountInfo.planDetail.maxDataSize
      ) {
        this.data.popup.sizeInfo = {
          currentTotalSize: this.accountInfo.totalDataSize,
          fileSize: file.size,
          newTotalSize: this.accountInfo.totalDataSize + file.size,
          maxSize: this.accountInfo.planDetail.maxDataSize
        }
        this.showPopup('upperLimitDataSize')
        this.$nextTick(() => {
          this.data.upload.resetInputValue = true
          this.data.upload.createInfo.datasetFormValidate.duplicate = undefined
        })
      }
    },
    initUploadState: function () {
      this.data.upload.uploadLearningDataErrors = Object.assign(
        this.data.upload.uploadLearningDataErrors,
        {
          flag: false,
          errors: []
        }
      )

      this.data.upload.uploadLearningDataWarnings.fileType = null
      this.data.upload.uploadLearningDataWarnings.reasonList = []
    },
    inputCreateForm: function (obj) {
      const { type, form } = obj

      if (type === 'dataset') {
        this.data.upload.createInfo.datasetFormValidate = datasetValidator(
          this.projectItems,
          form
        )
      } else if (type === 'datasource') {
        this.data.upload.createInfo.datasourceFormValidate =
          datasourceValidator(this.datasourceList, form)
      }
    },
    resetStatus() {
      this.sidebar.status = 'select'
    },
    notPrevent() {
      this.correctTransition = true
    },
    restartPrevent() {
      this.correctTransition = false
    },
    checkPrevent(nextPageName) {
      return (
        !checkOuterTraining(nextPageName) &&
        !checkCorrectBackPage('dataset', nextPageName) &&
        !this.correctTransition
      )
    },
    closePrevent() {
      this.$refs.preventLeave.closePopup()
    },
    pageBack() {
      this.$router.push({
        name: 'projectDetail',
        params: {
          projectId: this.projectId
        }
      })
    },
    setAccountInfo: function () {
      this.$set(this.data.body.mainData, 'accountInfo', this.accountInfo)
    },
    setUsageRestriction: function ({ projectId }) {
      const projectInfo = this.projectList[projectId]
      const sourceTemplateInfo = projectInfo.sourceTemplate

      const usageRestriction =
        sourceTemplateInfo !== null
          ? sourceTemplateInfo.usageRestriction
          : {
              basic: true,
              education_1: true,
              free: true
            }

      this.$set(this.data.body.mainData, 'usageRestriction', usageRestriction)
    },
    goManualDatasetType() {
      const url =
        this.$urls.manual + 'train-dataset-setting/データの形式について/'
      window.open(url, '_blank')
    }
  },
  watch: {
    // データセットが存在しない場合のエラー以降では起きないのでここだけ
    fetchDetailError() {
      this.data.errorDataset.flag = false
      this.data.errorDataset.cautionFlag = false
      this.data.errorDataset.message = []
      this.data.errorDataset.inPreprocessingOtherDataset = false
      this.$nextTick(() => {
        this.data.errorDataset.cautionFlag = true
        this.data.errorDataset.message.push(
          this.$t('datasetSetting.selectDataset.fetchError')
        )
        this.data.errorDataset.flag = true
        this.resetDataset()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.prevent-leave-text {
  display: flex;
  flex-direction: column;
  grid-row-gap: $space-sub;
  ::v-deep {
    .prevent-leave-text-emphasis {
      color: $text-decoration;
    }
    .prevent-leave-text-caution {
      color: $text-caution;
    }
  }
}
</style>
